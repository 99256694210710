<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      do-route-filters
      headers-filterable
      no-meta-check
      prune-search-filters
      show-filter
      sortable
      resource="vouchers"
      route-base="/reports/statistics/vouchers"
      :document-export="documentExport"
      :force-all-export-columns="true"
      export-document-type="VouchersExport"
      :header-name-to-export-column-map="headerNameToExportColumnMap"
      :export-filename-prefix="$t('pages.reports.statistics.vouchers.vouchers')"
      :handle-export="handleExport"
      :custom-resource="customResource()"
      :headers-config="headersConfig"
      :headers-default-hide="headersDefaultHide"
      :headers="headers"
      :locale="locale"
      :resource-query="resourceQuery"
      :row-key="(row) => row.$index"
      :search-filters="filtersList"
      :show-operations="false"
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      @headers-config="handleHeadersConfig"
      @search-filter-submit="
        $ampli.eventWithBaseProps('statisticsFiltersSearchButtonClick')
      "
    />
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import Decimal from 'decimal.js-light'
import { mapGetters } from 'vuex'
import qs from 'qs'
import datatableHeadersConfig from '@/mixins/datatable-headers-config'
import { useExportsStore } from '@/store/exports'

const types = {
  CREATE: 'create',
  UPDATE: 'update:update',
  DECREASE: 'update:decrement',
  INCREMENT: 'update:increment'
}

export default {
  metaInfo() {
    return {
      title: this.$t('pages.reports.statistics.vouchers.vouchers')
    }
  },
  name: 'VouchersReports',
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { headersConfig, handleHeadersConfig } = datatableHeadersConfig(
      'settings.headerFilters.reports.statistics.vouchers'
    )
    return {
      headersConfig,
      handleHeadersConfig
    }
  },
  data() {
    return {
      isLegacy: true,
      headersDefaultHide: [
        'delta',
        'redeemed_id',
        'redeemed_external_custom_id',
        'comment',
        'issuer'
      ],
      headers: [
        {
          label: this.$t('pages.reports.statistics.vouchers.voucher_number'),
          field: 'voucher_number',
          sortable: true,
          minWidth: 155,
          truncate: true,
          fixed: 'left'
        },
        {
          label: this.$t('pages.reports.statistics.vouchers.amount_old'),
          field: 'amount_old',
          sortable: true,
          minWidth: 155,
          type: 'currency',
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.amount_old)) {
              return this.$formatCurrency(row.amount_old, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.vouchers.amount_new'),
          field: 'amount_new',
          sortable: true,
          minWidth: 155,
          type: 'currency',
          align: 'right',
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            if (Number.isFinite(row.amount_new)) {
              return this.$formatCurrency(row.amount_new, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.vouchers.delta'),
          field: 'delta',
          sortable: true,
          minWidth: 120,
          truncate: true,
          fallback: '-',
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.delta)) {
              return this.$formatCurrency(row.delta, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          label: this.$t('common.headers.type.title'),
          field: 'type',
          sortable: true,
          minWidth: 160,
          truncate: true,
          fallback: '-',
          formatter: ({ type }) => this.typeOptions[type] || '-'
        },
        {
          label: this.$t('pages.reports.statistics.vouchers.redeemed_branch'),
          field: 'redeemed_branch',
          sortable: true,
          minWidth: 160,
          truncate: true,
          fallback: '-'
        },
        {
          label: this.$t('pages.reports.statistics.vouchers.redeemed_email'),
          field: 'redeemed_email',
          sortable: true,
          minWidth: 150,
          truncate: true,
          fallback: '-'
        },
        {
          label: this.$t('pages.reports.statistics.vouchers.redeemed_id'),
          field: 'redeemed_id',
          sortable: true,
          minWidth: 160,
          fallback: '-',
          truncate: true
        },
        {
          label: this.$t('pages.reports.statistics.vouchers.issued_at'),
          field: 'issued_at',
          sortable: true,
          type: 'date',
          minWidth: 150,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            if (!row.issued_at) return '-'
            return this.$date.formatDateTimeWithTimezone(row.issued_at)
          }
        },
        {
          label: this.$t('pages.reports.statistics.vouchers.valid_until'),
          field: 'valid_until',
          sortable: true,
          type: 'date',
          minWidth: 150,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            if (!row.valid_until) return '-'
            return this.$date.formatDateTimeWithTimezone(row.valid_until)
          }
        },
        {
          label: this.$t('pages.reports.statistics.vouchers.redeemed_at'),
          field: 'redeemed_at',
          sortable: true,
          type: 'date',
          minWidth: 150,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            if (!row.redeemed_at) return '-'
            return this.$date.formatDateTimeWithTimezone(row.redeemed_at)
          }
        },
        {
          label: this.$t(
            'pages.reports.statistics.vouchers.redeemed_external_custom_id'
          ),
          field: 'redeemed_external_custom_id',
          sortable: true,
          minWidth: 250,
          truncate: true,
          fallback: '-'
        },
        {
          label: this.$t('pages.reports.statistics.vouchers.comment'),
          field: 'comment',
          sortable: true,
          minWidth: 260,
          truncate: true,
          fallback: '-'
        },
        {
          label: this.$t('pages.reports.statistics.vouchers.issuer'),
          field: 'issuer',
          sortable: true,
          minWidth: 120,
          truncate: true,
          fallback: '-'
        }
      ],
      headerNameToExportColumnMap: {
        voucher_number: 'voucher_number',
        amount_old: 'amount_old',
        amount_new: 'amount_new',
        delta: 'delta',
        type: 'type',
        redeemed_branch: 'redeemed_branch',
        redeemed_email: 'redeemed_email',
        redeemed_id: 'redeemed_id',
        issued_at: 'issued_at',
        valid_until: 'valid_until',
        redeemed_at: 'redeemed_at',
        redeemed_external_custom_id: 'redeemed_external_custom_id',
        comment: 'comment',
        issuer: 'issuer'
      }
    }
  },
  computed: {
    ...mapGetters({
      pageSize: 'Config/getPageSizes',
      branchNumber: 'Config/getCurrentBranchNumber',
      currentLocation: 'Config/getCurrentLocation',
      timeZone: 'Config/getTimeZone',
      locale: 'Config/getLocale',
      defaultDateSelected: 'Config/getDefaultDateSelected',
      currencies: 'Config/getAvailableCurrencies'
    }),
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter
      return parsedQuery || {}
    },
    currentCurrency() {
      return this.parsedQuery.currency || 'EUR'
    },
    resourceQuery() {
      return {
        legacy: this.isLegacy,
        issued_at_start: this.parsedQuery.issued_at?.start,
        issued_at_end: this.parsedQuery.issued_at?.end,
        redeemed_at_start: this.parsedQuery.redeemed_at?.start,
        redeemed_at_end: this.parsedQuery.redeemed_at?.end,
        valid_until_start: this.parsedQuery.valid_until?.start,
        valid_until_end: this.parsedQuery.valid_until?.end,
        voucher_number: this.parsedQuery.voucher_number,
        redeemed_id: this.parsedQuery.redeemed_id,
        redeemed_email: this.parsedQuery.redeemed_email,
        redeemed_branch: this.branchNumber || this.parsedQuery.branch_number,
        comment: this.parsedQuery.comment,
        type: this.parsedQuery.type,
        delta: this.parsedQuery.delta,
        branch_group: this.parsedQuery.branch_group || undefined,
        amount_old: this.parsedQuery.amount_old,
        amount_new: this.parsedQuery.amount_new,
        currency: this.parsedQuery.currency,
        size: this.pageSize.main
      }
    },

    filtersList() {
      return [
        {
          name: 'voucher_number',
          type: 'input',
          label: this.$t('pages.reports.statistics.vouchers.voucher_number'),
          placeholder: this.$t('common.inputs.placeholders.input')
        },
        {
          name: 'type',
          type: 'select',
          label: this.$t('common.headers.type.title'),
          options: [
            {
              value: types.CREATE,
              label: this.typeOptions[types.CREATE]
            },
            {
              value: types.DECREASE,
              label: this.typeOptions[types.DECREASE]
            },
            {
              value: types.INCREMENT,
              label: this.typeOptions[types.INCREMENT]
            }
          ]
        },
        ...(this.currencies.length > 1
          ? [
              {
                name: 'currency',
                type: 'currency-select',
                label: this.$t('pages.reports.statistics.vouchers.currency'),
                value: this.currentCurrency,
                options: this.currencies
              }
            ]
          : []),
        {
          name: 'amount_old',
          type: 'currency-input',
          label: this.$t('pages.reports.statistics.vouchers.amount_old'),
          currency: this.currentCurrency
        },
        {
          name: 'amount_new',
          type: 'currency-input',
          label: this.$t('pages.reports.statistics.vouchers.amount_new'),
          currency: this.currentCurrency
        },
        {
          name: 'delta',
          type: 'currency-input',
          label: this.$t('pages.reports.statistics.vouchers.delta'),
          currency: this.currentCurrency
        },
        {
          name: 'branch_number',
          type: 'remote-search-select',
          label: this.$t('pages.reports.statistics.vouchers.redeemed_branch'),
          resource: 'branchesV1',
          fetchHandler: 'getAll',
          optionsValue: 'branch_number',
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            fields: ['branch_number'],
            deleted: false
          })
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'redeemed_email',
          type: 'input',
          label: this.$t('pages.reports.statistics.vouchers.redeemed_email'),
          placeholder: this.$t('common.inputs.placeholders.input')
        },
        {
          name: 'comment',
          type: 'input',
          label: this.$t('pages.reports.statistics.vouchers.comment'),
          placeholder: this.$t('common.inputs.placeholders.input')
        },
        {
          name: 'issued_at',
          prop: ['issued_at_start', 'issued_at_end'],
          type: 'daterange',
          noFutureDates: true,
          preventDefaultDates: true,
          label: this.$t('pages.reports.statistics.vouchers.issued_at'),
          formatValue: (value) => this.$date.formatDateRange(value)
        },

        {
          name: 'redeemed_at',
          prop: ['redeemed_at_start', 'redeemed_at_end'],
          type: 'daterange',
          preventDefaultDates: true,
          noFutureDates: true,
          label: this.$t('pages.reports.statistics.vouchers.redeemed_at'),
          formatValue: (value) => this.$date.formatDateRange(value)
        },
        {
          name: 'valid_until',
          prop: ['valid_until_start', 'valid_until_end'],
          type: 'daterange',
          preventDefaultDates: true,
          noFutureDates: true,
          label: this.$t('pages.reports.statistics.vouchers.valid_until'),
          formatValue: (value) => this.$date.formatDateRange(value)
        }
      ]
    },
    documentExport() {
      return this.$checkPermissions({
        scopes: ['reports_statistics:vouchers:export']
      })
    },
    typeOptions() {
      return {
        [types.CREATE]: this.$t(
          'pages.voucher_systems.form.properties.actions.type.create.label'
        ),
        [types.DECREASE]: this.$t(
          'pages.voucher_systems.form.properties.actions.type.decrement.label'
        ),
        [types.INCREMENT]: this.$t(
          'pages.voucher_systems.form.properties.actions.type.increment.label'
        )
      }
    }
  },
  watch: {
    'pageSize.main': 'refresh'
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    customResource() {
      const self = this
      return {
        getAll() {
          async function mapResponse({ data, next }) {
            return {
              data: safeGet(data, '0.values') || [],
              metaData: {
                count: safeGet(data, '2.values.0.count') || 0
              },
              next: next ? async () => mapResponse(await next()) : next
            }
          }
          return new Promise((resolve, reject) => {
            th.analyticsHandlersV1({ timeout: 60000 })
              .analytics.reports.AnalyticsReportsVouchers.getAll(
                self.resourceQuery
              )
              .then((response) => {
                resolve(mapResponse(response))
              })
              .catch((error) => reject(error))
          })
        }
      }
    },
    getCellValue(header, row) {
      const cellValue = safeGet(row, header.value)
      const type = header.type
      if (header.value === 'sales_rep') {
        return this.$formatStaff(row) || '--'
      } else if (cellValue === null) {
        return this.$t('pages.reports.statistics.vouchers.null')
      } else if (type === 'number') {
        const d = new Decimal(cellValue)
        return d.toDecimalPlaces(4).toString()
      } else {
        return cellValue
      }
    },
    async handleExport(query) {
      this.$ampli.eventWithBaseProps('statisticsExportButtonClick')

      try {
        const {
          data
        } = await th
          .analyticsHandlersV1()
          .analytics.reports.AnalyticsReportsVouchers.getAll(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.reports.statistics.vouchers.vouchers',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV1',
              path: 'analytics.reports.AnalyticsReportsVouchers',
              handler: 'getAll',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.reports.statistics.vouchers.vouchers')
          })
        })
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.$refs.table.refresh()
      })
    }
  }
}
</script>
